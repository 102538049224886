.threes-pride-hero {
  background: url(../../assets/pageimg8.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
}

.threes-pride-content {
  display: flex; 
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-image:linear-gradient(to right, 
  rgba(0, 0, 0, 1),
  rgba(0, 0, 0, .3),
  rgba(0, 0, 0, .1),
  rgba(0, 0, 0, 0));
} 

.threes-pride-content h3 {
  font-size: 42px;
  font-family: var(--sans-serif);
  font-weight: 500;
  color: var(--color-white);
} 

.threes-pride-content h1 {
  font-size: 105px;
  font-family: var(--Open Sans);
  font-weight: 500;
  text-transform: capitalize;
  color: var(--color-golden);
} 

.threes__pride_main {
  padding: 0 40px!important;
}

.threes-pride-content p {
  display: flex; 
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 25px;
  margin: 0 auto;
  padding: 10px;
  font-family: var(--font-alt);
  font-weight: normal;
  text-transform: capitalize;
  color: var(--color-white);
  line-height: 30px;
}  


@media (max-width: 1494px) {
  .threes-pride-content h3 {
    font-size: 38px;
  }
  
  .threes-pride-content h1 {
    font-size: 85px;
  }
}

@media (max-width: 1200px) {
  .threes-pride-content h3 {
    font-size: 34px;
  }
  
  .threes-pride-content h1 {
    font-size: 80px;
  }
  
  .threes-pride-content p {
    font-size: 21px;
  }
}

@media (max-width: 1024px) {
  .threes-pride-content h3 {
    font-size: 34px;
  }
  
  .threes-pride-content h1 {
    font-size: 75px;
  }
  
  .threes-pride-content p {
    font-size: 19px;
    padding: 10px
  }
}

@media (max-width: 880px) {
  .trees-pride h3 {
    font-size: 30px;
  }
  
  .trees-pride h1 {
    font-size: 70px;
  }
}

@media (max-width: 782px) {
  .threes-pride-hero {
    height: 75vh;
  }
  
  .threes-pride-content {
    height: 75vh;
  } 
  .threes-pride-content h3 {
      font-size: 28px;
    }
    
    .threes-pride-content h1 {
      font-size: 55px;
    }
    
    .threes-pride-content p {
      font-size: 16px;
      line-height: 24px;
      padding: 10px;
    }
  }
  
  
  @media (max-width: 530px) {
  .threes-pride-content h3 {
      font-size: 23px;
    }
  
    .threes-pride-content h1 {
      font-size: 50px;
    }
  }
  
  @media (max-width: 480px) {
    .threes-pride-hero {
      height: 60vh;
    }
    
    .threes-pride-content {
      height: 60vh;
    } 
    
    .threes-pride-content h3 {
      font-size: 18px;
    }
  
    .threes-pride-content h1 {
      font-size: 34px;
    }
    
    .threes-pride-content p {
      font-size: 13px;
      line-height: 22px;
    }
  }