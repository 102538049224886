.malicious-page-container {
  background-color: #000;
}

.comment-heading {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 20px;
  margin-top: 4rem;
  color: var(--color-golden);
  font-family: var(--font-base);
  text-transform: uppercase;
}

@media (max-width: 660px) {
  .comment-heading {
    font-size: 2rem;
  }
}

@media (max-width: 520px) {
  .comment-heading {
    font-size: 1.5rem;
  }
}

