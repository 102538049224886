.video-player {
  background-color: #000;
  height: auto;
}

.new-videos-link {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
  font-family: var(--font-alt);
  color: var(--color-gray);
  font-size: 25px;
}

.new-videos-link:hover {
  color: var(--color-golden);
}

.video-iframe {
  max-width: 900px; 
  width: 100%;
  max-height: 800px;
  height: 500px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  border: none;
}

.latest-vid-content {
  width: 90%;
  max-width: 800px;
  margin: 0 auto; 
  text-align: center; 
  font-family: var(--font-alt);
  color: var(--color-white);
}

.latest-vid-title {
  padding: 10px;
  font-size: 25px;
  font-weight: 400;
}

.latest-vid-para {
  color: var(--color-grey);
  padding: 10px;
  font-size: 14px;
}

.latest-vid-views {
  color: var(--color-golden);
  padding: 10px;
  font-size: 16px;
}


@media screen and (max-width: 650px) {
  .new-videos-link {
    margin-top: 2rem;
    padding: 10px;
    font-size: 23px;
  }
  
  .video-iframe {
    max-width: 700px; 
    max-height: 600px;
    height: 300px;
  }
  
  .latest-vid-content {
    width: 90%; 
    max-width: 800px; 
    margin: 0 auto; 
    text-align: center;
    font-family: var(--font-alt);
    color: var(--color-white);
  }
  
  .latest-vid-title {
    padding: 10px;
    font-size: 20px;
    font-weight: 400;
  }
  
  .latest-vid-para {
    color: var(--color-grey);
    padding: 10px;
    font-size: 13px;
  }
  
  .latest-vid-views {
    color: var(--color-golden);
    padding: 10px;
    font-size: 14px;
  }
}