.discretion-message {
  position: fixed;
  top: 76px;
  left: 0;
  width: 100%;
  color: #f70303;
  padding: 10px;
  text-align: center;
  z-index: 9999;
}


.discretion-message p {
  margin: 0;
}


.discretion-message p {
  font-size: 18px;
}


.discretion-message p strong {
  font-weight: bold;
}


.discretion-message p em {
  font-style: italic;
}


@media screen and (max-width: 768px) {
  .discretion-message p{
    font-size: 14px;
  } 
}