
.body-book {
  width: 100%;
  min-height: 110vh;
  display: grid;
  place-items: center;
}

.row-book {
  width: 80%;
  max-width: 1170px;
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 30px;
}

.left-book {
  overflow: hidden;
}

.left-book img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right-book {
  display: flex;
  align-items: center;
}

.content-book {
  padding-left: 20px;
}

.book-title {
  font-family: var(--sans-serif);
  color: var(--color-white);
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
}

.feather-img-books {
  width: 45px;
}

.book-subtitle {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 72px;
  font-weight: 700;
  text-transform: capitalize;
  margin-top: .5rem;
}

.content-book p {
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 15px;
  font-family: var(--font-alt);
}



.content__button {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  margin-top: 2rem;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.content__button:hover {
  border: 1px solid var(--color-golden);
  color: var(--color-white);
  background: transparent;
}


@media (max-width: 1250px) {
  .book-subtitle {
    font-size: 58px;
  }
}

@media (max-width: 1250px) {
  .book-subtitle {
    font-size: 50px;
  }
}


@media (max-width: 991px) {
  
  .content-book {
    padding-left: 0;
  }

  .left-book {
    margin-bottom: 30px;
  }

  .row-book {
    margin-top: 4rem;
    width: 50%;
    grid-template-columns: 1fr;
  }

  .book-title {
    margin-top: -2rem;
    font-size: 22px;
  }
  .book-subtitle {
    font-size: 50px;
  }
  .content-book p {
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 5px;
  }
  .content__button {
    line-height: 24px;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}


@media (max-width: 768px) {
  .row-book {
    margin-top: 4rem;
    width: 60%;
    grid-template-columns: 1fr;
  }

  .book-title {
    margin-top: -3rem;
    font-size: 22px;
  }
  .book-subtitle {
    font-size: 35px;
  }
  .content-book p {
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 5px;
  }
  .content__button {
    line-height: 24px;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 450px) {
  .book-subtitle {
    font-size: 30px;
  }
}