.signup-auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border: 1px solid var(--color-gray);
  background-color: #040404;
  max-width: 500px;
  margin: 6rem auto 0;
}

.account-created {
  color:var(--color-golden)!important;
}

.error-login {
  color: red;
  margin-bottom: 1rem;
}

.signup-auth {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.auth-h2 {
  margin-bottom: 2rem;
  text-align: center;
  font-family: var(--font-base);
  color: var(--color-white);
  font-size: 30px;
  text-transform: capitalize;
}


.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  width: 100%;
}

.login-auth-para {
  font-size: 1rem;
  font-family: var(--font-alt);
  color: var(--color-white);
}

.login-auth-para:hover {
  color: var(--color-golden);
}

@media only screen and (max-width: 569px) {
  .signup-auth-container {
  max-width: 90%;


  padding: 1rem;
  margin-top: 6rem;
  }
 
  .auth-h2 {
  font-size: 24px;
  }
 
  .signup-auth {
  max-width: 100%;
  border: none;
  padding: 1rem;
  margin-top: 2rem;
  }
 
  .account-created {
  font-size: 1.5rem;
  }
 
  .form {
  max-width: 100%;
  border: none;
  padding: 1rem;
  }
 
  .form div {
  margin-bottom: 1rem;
  }
 
  .login-label {
  font-size: 18px;
  }
 
  .input {
  font-size: 0.9rem;
  }
 
  .signup-submit-btn[type="submit"] {
  background-color: var(--color-gray);
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  font-family: var(--font-alt);
  }
 
  .signup-submit-btn:hover {
  background-color: var(--color-grey);
  }
 
  .signup-auth-para {
  font-size: 0.9rem;
  }
 
  .signup-auth-para:hover {
  color: var(--color-golden);
  }
  }
