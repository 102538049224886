.playlist-body {
  background-color: #000;
  position: fixed;
}

.playlist-link {
  padding-bottom: .7rem;
  margin-left: 1.5rem;
  font-size: 22.5px;
  color: var(--color-white);
  font-family: var(--font-alt);
  text-decoration: none;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  background: #000;
}

.playlist-link:hover {
  color: var(--color-golden);
}

.playlist-videos-container {
  margin-left: 320px;
  height: 80vh;
  padding: 20px;
  background-color: #000;
  border-left: 1px solid var(--color-gray);
  overflow-y: auto;
}

.playlist-videos-section {
  display: flex;
  flex-wrap: wrap;
  background-color: #000;
  max-height: calc(100vh - 160px); 
  overflow-y: auto; 
}

.playlist-videos-section div {
  flex-basis: 22%;
  box-sizing: border-box;
  padding: 5px;
  margin: 15px;
  background-color: #080808;
  color: var(--color-white);
  font-family: var(--font-alt);
  font-size: 14px;
  border-radius: 5px;
}

.playlist-videos-section div img {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  opacity: .8;
}


.video-thumbnail {
  position: relative;
}


.video-icon-container {
  position: absolute;
  background-color: transparent !important;
  top: 30%;
  left: 30%;
  z-index: 1;
}

.playlist-video-icon {
  color: var(--color-golden);
  font-size: 40px;
  opacity: 1;
  transition: .3s ease-in-out;
}

.playlist-video-icon:hover {
  color: var(--color-crimson);
  
}

.playlist-para-title {
  background-color: #000;
  margin: 20px;
  margin-top: 1rem;
  height: 90px;
  padding: 15px;
  border-radius: 5px;
}

.playlist-para-views {
  margin-left: 2rem;
  color: var(--color-gray);
}



@media screen and (max-width: 1360px) {
  .playlist-videos-section div {
    flex-basis: 30%; 
  }
}

@media screen and (max-width: 1260px) {
  .playlist-videos-section div {
    flex-basis: 29%; 
  }
}

@media screen and (max-width: 1120px) {
  .playlist-videos-section div {
    flex-basis: 40%; 
  }
}

@media screen and (max-width: 900px) {
  .playlist-body {
    position: fixed;
    height: 2px;
    top: 20%;
  }

  .playlist-link {
    padding-bottom: 0rem;
    margin-top: 0rem;
    font-size: 17px;
    font-weight: 400;
  }

  .playlist-videos-container {
    margin-left: 0;
    border-left: none;
  }
  
  .playlist-videos-section div {
    flex-basis: 31%;
    padding: 5px;
    margin: 8px;
    font-size: 13px;  
  }
  
  .playlist-videos-section div img {
    width: 95%;
    margin-left: 8px;
  }
  
  .playlist-para-title {
    margin-top: 1rem;
    width: 217px;
    height: 80px;
  } 
} 

@media screen and (max-width: 888px) {
  .playlist-videos-section div {
    flex-basis: 40%;
    padding: 5px;
    margin-left: 3rem;
  }
  
  .playlist-videos-section div img {
    width: 95%;
    margin-left: -1rem;
  }

  .video-icon-container {
    left: 15%;
  }
  
  .playlist-para-title {
    margin-top: 1rem;
    margin-left: 2.5rem;
    width: 240px;
    height: 80px;
  } 

  .playlist-para-views {
    margin-left: 3.5rem;
  }
}  

@media screen and (max-width:771px) {
  .playlist-videos-section div {
    flex-basis: 45%;
    box-sizing: border-box;
    padding: 5px;
    margin: 15px;
  
  }
  
  .playlist-videos-section div img {
    margin-left: .1rem;
  }

  .video-icon-container {
    left: 33%;
  }
  
  
  .playlist-para-title {
    width: 250px;
    margin-left: 1.9rem;
  }
  
  .playlist-para-views {
    margin-left: 2rem;
    color: var(--color-gray);
  }
  
}

@media screen and (max-width:720px) {
  .playlist-videos-section div {
    flex-basis: 45%;
    padding: 5px;
    margin: 8px;
    font-size: 13px;  
  }

  .playlist-videos-section div img {
    width: 100%;
    margin-left: 8px;
  }

  .playlist-para-title {
    margin-top: 1rem;
    width: 217px;
    height: 80px;
  }
} 

@media screen and (max-width:626px) {
  .playlist-videos-section div {
    flex-basis: 30%;
    padding: 5px;
    margin: 8px;
    font-size: 13px;  
  }

  .playlist-videos-section div img {
    width: 95%;
    margin-left: 8px;
  }

  .playlist-para-title {
    margin-top: 1rem;
    margin-left: 1.4em;
    width: 212px;
    height: 80px;
  }
} 

@media screen and (max-width:626px) {
  .playlist-videos-section div {
    flex-basis: 30%;
    padding: 5px;
    margin: 8px;
    font-size: 13px;  
  }

  .playlist-videos-section div img {
    width: 95%;
    margin-left: 8px;
  }

  .playlist-para-title {
    margin-top: 1rem;
    margin-left: 1.4em;
    width: 212px;
    height: 80px;
  }
} 

@media screen and (max-width:592px) {
  .playlist-videos-section div {
    flex-basis: 30%;
    padding: 5px;
    margin: 8px;
    font-size: 13px;  
  }

  .playlist-videos-section div img {
    width: 95%;
    margin-left: 8px;
  }

  .playlist-para-title {
    margin-top: 1rem;
    margin-left: 1.4em;
    width: 200px;
    height: 80px;
  }
} 

@media screen and (max-width:568px) {
  .playlist-videos-section div {
    flex-basis: 30%;
    padding: 5px;
    margin: 8px;
    font-size: 13px;  
  }

  .playlist-videos-section div img {
    width: 100%;
    margin-left: 8px;
  }

  .playlist-para-title {
    margin-top: 1rem;
    margin-left: 1.4em;
    width: 180px;
    height: 80px;
  }
} 

@media screen and (max-width:568px) {
  .playlist-videos-section div {
    flex-basis: 45%;
    padding: 5px;
    margin: 8px;
    font-size: 13px;  
    margin-left: 3rem;
  }

  .playlist-videos-section div img {
    width: 100%;
    margin-left: -1rem;
  }

  .playlist-para-title {
    margin-top: 1rem;
    margin-left: 2rem;
    width: 350px;
    height: 80px;
  }

  .video-icon-container {
    left: 20%;
  }
} 

@media screen and (max-width:490px) {
  .playlist-videos-section div {
    flex-basis: 45%;
    padding: 5px;
    margin: 8px;
    font-size: 13px;  
    margin-left: 2rem;
  }

  .playlist-videos-section div img {
    width: 100%;
    margin-left: -1rem;
  }

  .playlist-para-title {
    margin-top: 1rem;
    margin-left: 1rem;
    width: 300px;
    height: 80px;
  }

  .video-icon-container {
    left: 25%;
  }
} 

@media screen and (max-width:434px) {
  .playlist-videos-section div {
    flex-basis: 45%;
    padding: 5px;
    margin: 8px;
    font-size: 13px;  
    margin-left:3rem;
  }

  .playlist-videos-section div img {
    width: 100%;
    margin-left: -1.5rem;
  }

  .playlist-para-title {
    margin-top: 1rem;
    margin-left: 1rem;
    width: 220px;
    height: 80px;
  }

  .video-icon-container {
    left: 2%;
  }
} 