.white-background {
  background-color: white;
}

.post-header {
  width: 100%;
  height: 500px;
  background:#000;
}
.post-header-02 {
  background-color: #fff;
}

.post-container-blog {
  max-width: 800px;
  margin: auto;
  width: 100%;
  margin-bottom: 12rem;
  
}

.header-content-blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.back-home {
  color: var(--color-gray);
  font-size: 1.6rem;
  margin-bottom: 1rem; 
  margin-top: 2.5rem;
}

.back-home:hover{
  color: var(--color-golden);
}

.header-title-blog {
  width: 90%;
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 2.5rem;
  color: var(--color-white);
  font-family: var(--font-base);
}

.header-img-blog {
  width: 100%;
  height: 520px;
  object-fit: cover;
  object-position: center;
  border: .2rem solid var(--color-golden);
}

.post-content-blog {
  margin-top: 2rem !important;
}

.post-text-blog  {
  font-family: var(--font-base);
  font-weight: 900;
  font-size: 26px;
  line-height: 1.7rem;
  margin: 1rem 0;
  color: #000;
}

.post-para-blog  {
  font-family: var(--font-alt);
  color: var(--color-black);
  font-size: 16px;
  text-align: justify;
}

.author-details-info {
  width: 100%;
  height: 400px;
  background:#000;
  margin-top: -10rem;
}

.author-info-blog {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.author-content-blog {
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
}

.author-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 800px;
  height: 400px;
  margin-top: -10rem; 
  background: white;
  border: 1rem solid var(--color-black);
}

.author-image-blogpost {
  width: 100%;
  height: 320px;
  margin: 1rem 1rem;
  object-fit: cover;
  object-position: center;
}

.author-text-blogpost {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 320px;
  margin: 1rem 1rem;
  color: #000;
}

.author-name-blogpost {
  font-size: 25px;
  font-family: var(--font-base);
  font-weight: 900;
  color: #000;
}

.feather-post-img {
  width: 45px;
}

.author-para-blogpost {
  margin-top: .5rem;
  font-size: 16px;
  line-height: 1.7rem;
  font-family: var(--font-alt);
  color: var(--color-grey)
}

.footerblog-icon-links {
  margin-top: 2.5rem;
  margin-bottom: -1rem;
}

.footerblog-icon-links a {
  color: var(--color-white);
  margin: 0.5rem;
  font-size: 26px;
  cursor: pointer;
}

.footerblog-icon-links a:hover {
  color: var(--color-golden);
}


@media (max-width: 800px) {
  .post-container-blog {
    margin: 0 auto;
    width: 95%;
  }

  .header-title-blog {
    font-size: 3rem;
  }

  .author-details-info {
    width: 100%;
    height: 480px;
    margin-top: 25rem;
  }

  .author-content-blog {
    margin: 0 auto;
    width: 90%; 
  }

  .author-image-container {
    flex-direction: column;
    height: 640px;
    margin-top: -20rem; 
  }

  .author-image-blogpost {
    width: 100%;
    height: 320px;
    margin: 0;
    object-fit: fill;
    object-position: center;
  }

  .author-name-blogpost {
    margin-left: 1rem;
  }

  .feather-post-img {
    width: 45px;
    margin-left: 1rem;
  }
  
  .author-para-blogpost {
    margin-left: 1rem;
  }

  .footerblog-icon-links {
    margin-top: 1.5rem;
    margin-bottom: -0.5rem;
  }
  
  .footerblog-icon-links a {
    font-size: 24px;
  }
}

@media (max-width: 600px) {
  .header-title-blog {
    font-size: 2.5rem;
  }
}

@media (max-width: 450px) {
  .header-title-blog {
    font-size: 2rem;
  }
}