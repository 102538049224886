.chief-vid-body {
  width: 100%;
  min-height: 120vh;
  display: grid;
  place-items: center;
  margin-top: 4rem;
  background-image: linear-gradient(
    180deg,
    #000 30%,
    #01e1ff10 70%
  ); 
}

.row-vid-chief {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1800px;
  gap: 50px 80px;
}

.left-vid-chief {
  position: relative;
}

.left-vid-chief img {
  object-fit: cover;
  width: 900px;
  height: 100%;
  border-radius: 10px;
  border: 2px solid var(--color-golden);
}

.right-vid-chief {
  position: relative;
}

.right-vid-chief img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 600px;
  border: 2px solid var(--color-golden);
  margin: 2rem;
}

.small-img-vid img {
  object-fit: cover;
  width: 420px;
  height: 24.2vh; 
  border-radius: 10px;
}

.chief-card-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: .40%;
  left: .2%;
  width: 99.6%;
  height: 99%;
  padding: 90px;
  overflow: hidden;
  bottom: 5px;
  color: var(--color-white);
  background: #00000080;
  border-radius: 10px;
  transition: 0.3s ease-in;
}

.chief-card-main:hover {
  background: #000000d2;
  backdrop-filter: blur(5px);
  border-radius: 10px;

}


.chief-vid-title {
  font-size: 50px;
  text-transform: capitalize;
  font-family: var(--sans-serif);
  font-weight: 900;
  padding: 15px;
}

.chief-para-main {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 1rem;
  margin: 0 auto;
}

.chief-card {
  position: absolute;
  width: 85.3%;
  height: 27.3%;
  bottom: 5px;
  background: #00000080;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.3s ease-in;
}

.chief-card:hover {
  background: #000000d2;
  backdrop-filter: blur(5px);
  border-radius: 10px;
}

.chief-card-two {
  top: 4.4%;
  left: 7.4%;
}

.chief-card-three {
  top: 36.3%;
  left: 7.4%;
}

.chief-card-four {
  top: 68.3%;
  left: 7.4%;
}

.left-vid-title {
  margin-bottom: 10px;
  font-size: 28px;
  text-transform: capitalize;
  font-family: var(--sans-serif);
  color: var(--color-white);
}

.chief-para {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-size: 16px;
  margin: 0 auto;
}

.chief-vid-title,
.left-vid-title {
  text-align: center;
}

.chief-para-main,
.chief-para {
  text-align: center;
}


.play-btnM {
  position: absolute;
  width: 100px;
  top: 70%;
  left: 40%;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer; 
  transition: all ease .2s;
  font-size: 18px;
  font-weight: 900;
  font-family: var(--font-base);
  letter-spacing: 0.04em;
  line-height: 18px;
  color: var(--color-black);
  background-color: var(--color-crimson);
}

.play-btnM:hover {
  border-color: var(--color-golden);
  color: var(--color-golden);
  background: transparent;
}


@media only screen and (max-width: 1880px) {
  .row-vid-chief {
    margin-top: 6rem;
  }

  .chief-card-two {
    top: 4.4%;
  }
}

@media only screen and (max-width: 1463px) {
  .row-vid-chief {
    margin-top: 2rem;
  }

  .right-vid-chief img {
    min-width: 900px;
  }
  
  .small-img-vid img {
    height:50vh; 
  }
  
  .chief-card {
    width: 92.7%;
    height: 30%;
    top: 99%;
    bottom: 5px;
  }

  .chief-card-two {
    top: 2.4%;
    left: 3.7%;
  }
  
  .chief-card-three {
    top: 35%;
    left: 3.7%;
  }
  
  .chief-card-four {
    top: 67.6%;
    left: 3.7%;
  } 
}

@media only screen and (max-width: 965px) {
  .left-vid-chief {
    margin-bottom: -50px;
  }
  
  .left-vid-chief img {
    width: 700px;
  }

  .right-vid-chief img {
    min-width: 700px;
  }

  .chief-vid-title {
    font-size: 45px;
  }
  
  .chief-card-main {
    left: .3%;
    height: 98.5%;
  }

  .chief-card {
    width: 91%;
    height: 30%;
  }
  
  .chief-card-two {
    top: 2.4%;
    left: 4.6%;
  }
  
  .chief-card-three {
    top: 35%;
    left: 4.6%;
  }
  
  .chief-card-four {
    top: 67.5%;
    left: 4.6%;
  }
  
  
  .left-vid-title {
    margin-bottom: 10px;
    font-size: 30px;
  }
  
  .chief-para {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  .play-btnM {
    top: 76%;
  }
}

@media only screen and (max-width: 767px) {
  .left-vid-chief img {
    width: 475px;
    height:60vh;
  }

  .chief-card-main {
    top: .6%;
    left: .9%;
    width: 98.7%;
    height: 98.4%;
  }
  
  .chief-vid-title {
    font-size: 24px;
  }
  
  .chief-para-main {
    font-size: 15px;
  }

  .right-vid-chief img {
    min-width: 475px;
  }
  
  .small-img-vid img {
    height: 25vh; 
  }

  .chief-card {
    width: 87%;
    height: 27.2%;
  }
  
  .chief-card-two {
    top: 4.5%;
    left: 6.5%;
  }
  
  .chief-card-three {
    top: 36.4%;
    left: 6.5%;
  }
  
  .chief-card-four {
    top: 68.4%;
    left: 6.5%;
  }

  .left-vid-title {
    font-size: 24px;
  }
  
  .chief-para {
    font-size: 13px;
  }

  .play-btnM {
    top: 80%;
    left: 40%;
    width: 90px;
    height: 30px;
    line-height: 12px;
  } 
}

@media only screen and (max-width: 550px) {
.left-vid-chief img {
    width: 350px;
    height: 60vh;
  }
  
  .right-vid-chief img {
    width: 100%;
    min-width: 350px;
    height:80vh;
  }
  
  .small-img-vid img {
    width: 350px;
    height: 30vh; 
  }
  
  .chief-card-main {
    top: .5%;
    left: 1.1%;
    width: 98.1%;
    height: 98.5%;
    padding: 30px;
  }
  
  .chief-vid-title {
    font-size: 23px;
  }
  
  .chief-para-main {
    font-size: 14px;
  }
  
  .chief-card {
    width: 83%;
    height: 28%;
  }
  
  .chief-card-two {
    top: 3.9%;
    left: 8.6%;
  }
  
  .chief-card-three {
    top: 36%;
    left: 8.6%;
  }
  
  .chief-card-four {
    top: 68.2%;
    left: 8.6%;
  }

  .left-vid-title {
    margin-bottom: 8px;
    font-size: 23px;
  }
  
  .chief-para {
    font-size: 14px;
  }

  .play-btnM {
    top: 80%;
    left: 36%;
  }
}

@media only screen and (max-width: 415px) {
  .left-vid-chief img {
      width: 300px;
      height:60vh;
    }

    .right-vid-chief img {
      min-width: 300px;
      
    }
    
    .small-img-vid img {
      width: 300px;
    }
    
    .chief-card-main {
      width: 98.5%;
      height: 98.5%;
      top: .5%;
      left: .8%;
    }
    
    .chief-vid-title {
      font-size: 19px;
    }
    
    .chief-para-main {
      font-size: 13px;
    }
    
    .chief-card {
      width: 82%;
      height: 27.8%;
    }
    
    .chief-card-two {
      top: 4%;
      left: 9%;
    }
    
    .chief-card-three {
      top: 36.2%;
      left: 9%;
    }
    
    .chief-card-four {
      top: 68.2%;
      left: 9%;
    }
  
    .left-vid-title {
      margin-bottom: 8px;
      font-size: 19px;
    }
    
    .chief-para {
      font-size: 13px;
    }

    .play-btnM {
      top: 80%;
      left: 34%;
    }
  }

@media only screen and (max-width: 375px) {
    .play-btnM {
      top: 80%;
      left: 34%;
    }
  }