.scrollToTop {
  background-color:  var(--color-black);
  border-radius: 100%;
  position: fixed;
  height: 3rem;
  width: 3rem;
  bottom: 40px;
  right: 40px;
  z-index: 2000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollToTop svg {
    color: var(--color-crimson);
    font-size: 2rem;
  }

  .scrollToTop svg:hover {
    border-radius: 100%;
    font-size: 2.5rem;
    background-color:  var(--color-black);
    color: var(--color-golden);
}
