.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000;
  padding: 1rem 2rem;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.app__navbar-logo img {
  width: 50px;
  height: auto;
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;  
  margin-right: 2rem;
}

.app__navbar-links li {
  margin: 0 1rem;
  cursor: pointer;
  font-size: 25px;
  font-family: var(--sans-serif);
  color: var(--color-white);
}

.app__navbar-links li:hover {
  color: var(--color-grey);
}


.button-navA {
  text-decoration: none;
  transition: .5s ease;
  font-size: 25px;
  font-family: var(--sans-serif);
  background-color: transparent;
  color: var(--color-white);
  border: none;
  margin-right: 2rem;
  padding-right: 2rem;
  padding-left: 20px;
}

.button-navA:hover {
  color: var(--color-grey);
} 
.imagine-nation-header {
  display: none;
}


.app__navbar-smallscreen {
  display: none;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  transition: .5s ease;
  flex-direction: column;
  z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: var(--color-golden);
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.app__navbar-smallscreen_links {
  list-style: none;
}

.app__navbar-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--color-golden);
  font-size: 2rem;
  text-align: center;
  font-family: var(--sans-serif);
}

.app__navbar-smallscreen_links li:hover {
  color: var(--color-white);
}

.button-nav {
  font-size: 2rem;
  color: var(--color-golden);
  cursor: pointer;
  text-decoration: none;
  transition: .5s ease;
  font-family: var(--sans-serif);
  background-color: transparent;
  border: none;
}

.button-nav:hover {
  color: var(--color-white);
}


@media screen and (min-width: 2000px) {
  .app__navbar-logo img {
    width: 20%;
    height: auto;
  }
}

@media screen and (max-width: 1150px) {
  .app__navbar-links {
      display: none;
  }

  .app__navbar-smallscreen {
      display: flex;
  }

  .imagine-nation-header {
    display: block;
    color: var(--color-golden);
    font-size: 2.5rem;
    font-family: var(--sans-serif);
  }
}

@media screen and (max-width: 650px) {
  .app__navbar {
      padding: 1rem;
  }

  .app__navbar-login {
      display: none;
  }
}

@media screen and (max-width: 450px) {
  .imagine-nation-header {
    display: block;
    color: var(--color-golden);
    font-size: 2rem;
    font-family: var(--sans-serif);
  }
}