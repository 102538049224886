.chief-body {
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.row-chief {
  width: 85%;
  max-width: 1600px;
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 30px; 
}

.left-chief {
  overflow: hidden;
}

.left-chief img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-mask-image: linear-gradient(black, transparent);
  mask-image:linear-gradient(black, transparent);
  border: 1px solid var(--color-golden);
}

.right-chief {
  display: flex;
  align-items: center;
}

.content-chief-main {
  padding-left: 20px;
}

.chief-title {
  font-family: var(--sans-serif);
  color: var(--color-white);
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
}

.feather-img {
  width: 45px;
}


.chief-subtitle {
  font-family: var(--font-base);
  font-weight: 500;
  color: var(--color-golden);
  font-size: 70px;
  text-transform: capitalize;
  margin-top: .5rem;
}

.content-chief-main p {
  font-size: 16px!important;
  line-height: 26px;
  padding-bottom: 15px;
}

.chief__button_home {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  margin-top: 2rem;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all ease .2s;
  height: 38px;
}

.chief__button_home:hover {
  border: 1px solid var(--color-golden);
  color: var(--color-white);
  background: transparent;
}


@media (max-width: 1680px) {
  .row-chief {
    margin: 4rem;
    width: 90%;
  }  
}

@media (max-width: 1400px) {
  .row-chief {
    margin: 4rem;
    width: 95%;
  }  
}

@media (max-width: 1250px) {
  .row-chief {
    width: 95%;
  }  
}


@media (max-width: 1030px) {
  .content-chief-main {
    padding-left: 0;
  }

  .left-chief {
    margin-bottom: 30px;
  }

  .row-chief {
    margin-top: 4rem;
    width: 75%;
    grid-template-columns: 1fr;
  }

  .chief-title {
    margin-top: -3rem;
    font-size: 22px;
  }

  .chief-subtitle {
    font-size: 45px;
  }

  .content-chief-main p {
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 5px;
  }

  .chief__button {
    width: 140px;
    height: 47px;
    transition: all ease .2s;
  }
}


@media (max-width: 768px) {
  .row-chief {
    margin-top: 4rem;
    width: 75%;
    grid-template-columns: 1fr;
  }

  .chief-title {
    margin-top: -3rem;
    font-size: 20px;
  }

  .chief-subtitle {
    font-size: 40px;
  }

  .content-chief-main p {
    font-size: 13px;
    padding-bottom: 5px;
  }
  .chief__button {
    line-height: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 450px) {
  .row-chief {
    width: 95%;
  }

  .chief-subtitle {
    font-size: 35px;
  }
  .chief__button {
    width: 110px;
    height: 45px;
  }
} 