.book-cover {
  background: url(../../assets/Egypt5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.app__bgO {
  background-image:linear-gradient(to right, 
  rgba(0, 0, 0, 1),
  rgba(0, 0, 0, .9),
  rgba(0, 0, 0, .8),
  rgba(0, 0, 0, .4));
}