.blog-hero {
  background: url(../../assets/pageimg8.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: auto;
}

.blog-content {
  display: flex; 
  position: relative;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image:linear-gradient(to right, 
  rgba(0, 0, 0, 1),
  rgba(0, 0, 0, .3),
  rgba(0, 0, 0, .1),
  rgba(0, 0, 0, 0));
} 

.blog-content h3 {
  font-size: 42px;
  color: var(--color-white);
  text-transform: capitalize;
  font-family: var(--sans-serif);
  font-weight: 500;
} 

.blog-content h1 {
  font-size: 105px;
  font-family: var(--Open Sans);
  font-weight: 500;
  text-transform: capitalize;
  color: var(--color-golden);
} 

@media (max-width: 1494px) {
  .blogs-content h3 {
    font-size: 38px;
  }
  
  .blogs-content h1 {
    font-size: 85px;
  }
}

@media (max-width: 1200px) {
  .blog-content h3 {
    font-size: 34px;
  }
  
  .blog-content h1 {
    font-size: 80px;
  }
}

@media (max-width: 1024px) {
  .blog-content h3 {
    font-size: 34px;
  }
  
  .blog-content h1 {
    font-size: 75px;
  }
}

@media (max-width: 880px) {
  .blog-content h3 {
    font-size: 30px;
  }
  
  .blog-content h1 {
    font-size: 70px;
  }
}

@media screen and (max-width: 782px) {
  .blog-hero {
    height: 75vh;
  }
  
  .blog-content {
    height: 75vh;
  } 

  .blog-content h3 {
    font-size: 28px;
  }
  
  .blog-content h1 {
    font-size: 55px;
  }
}

@media screen and (max-width: 539px) {
  .blog-content h3 {
    font-size: 23px;
  } 
  
  .blog-content h1 {
    font-size: 50px;
  } 
}

@media screen and (max-width: 480px) {
  .blog-hero {
    height: 60vh;
  }
  
  .blog-content {
    height: 60vh;
  } 
  .blog-content h3 {
    font-size: 18px;
  } 
  
  .blog-content h1 {
    font-size: 34px;
  } 
}