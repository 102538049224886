.full-container {
  background-image: linear-gradient(180deg,
  #000 50%,
  #01e1ff10 100%); 
  height: 800px;
}

.title-bloggie {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 70px;
  font-weight: 500;
  font-family: var(--font-base);
  text-transform: capitalize;
  color: var(--color-golden);
  margin-top: 4rem;
}

.blog-container {
  margin: 20px;
}

.blog-row {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card-01 {
  position: relative;
  flex: 1;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  /* Adjust the dimensions of the container */
  width: 100%;
  height: 520px;
  max-width: 1120px;
  object-fit: contain;
}

.blog-img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  border-radius: 5px;
}


.card-01::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.288);
  z-index: 0;
  border-radius: 5px;
}

.blog-content-one {
  position: absolute;
  text-align: left;
  bottom: 10%;
  left: 0;
  margin: 0 2rem;
  z-index: 1;
  font-family: var(--font-alt);
}

.blog-title {
  font-size: 50px;
  margin-bottom: 1rem;
  font-family: var(--font-base);
  color: var(--color-crimson);
  text-transform: capitalize;
  margin-left: -1rem;
}

.blog-para {
  color: var(--color-crimson);
  font-size: 20px;
  margin-bottom: 1rem;
  margin-left: -1rem;
}

.blog-button {
  display: inline-block;
  margin-top: 1.5rem;
  padding: 0.5rem 1.5rem;
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  font-size: 16px;
  border-radius: 5px;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  width: 110px;
  height: 38px;
  transition: all ease .2s;
  margin-left: -1rem;
}

.blog-button:hover {
  border: 1px solid var(--color-golden);
  color: var(--color-white);
  background: transparent;
} 

.blog-container-two {
  margin: 20px;
}

.blog-row-two {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap; 
}

.card-03 {
  background: #000;
  position: relative;
  flex: 1;
  width: 330px;
  height: 535px;
  margin: 40px;
  border-radius: 10px;
  padding: 1.5rem;
}

.blog-img-two {
  width: 100%;
  height: 25vh;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid var(--color-gray);
}

.author-info {
  position: absolute;
  z-index: 1;
  text-align: left;
  font-size: 18px;
  font-family: var(--font-alt);
  margin: 0 1rem;
}

.author-img {
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 0.5rem;
  margin-left: -.9rem; 
}


.blog-author {
  margin-top:-1.5rem;
  margin-left: 2.5rem;
  color: var(--color-grey);
  font-size: 13px;
}

.blog-two {
  margin: 1.2rem 0 0 0rem;
  color: var(--color-crimson);
  font-family: var(--font-base);
  font-size: 27px;
  text-transform: capitalize;
}

.blog-para-two {
  color: var(--color-grey);
  margin: .8rem 0 0 0rem;
  font-family: var(--font-alt);
  font-size: 15px;
}

.read-posts {
  justify-content: center;
  align-items: center;
  text-align: center;
  
}

.read-post-blog {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
  margin-top: -2rem;
  margin-bottom: 3rem;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.read-post-blog:hover {
  border: 1px solid var(--color-golden);
  color: var(--color-white);
  background: transparent;
} 

@media only screen and (max-width: 1130px) {
  .title-bloggie {
    font-size: 65px;
  }
  .card-03 {
    min-width: 525px;
    height: 590px;
    margin: 40px;
  }
}

@media only screen and (max-width: 800px) {
  .title-bloggie {
    font-size: 50px;
  }

  .blog-content-one {
    bottom: 0%;
    left: 0;
  }

  .card-01 {
    flex: 100%;
    max-width: 600px;
  }

  .blog-button {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  .card-03 {
    flex: 100%;
    max-width: 600px;
  }
}

@media only screen and (min-width: 451px) and (max-width: 600px) {
  .title-bloggie {
    font-size: 45px;
  }
  
  .blog-content-one {
    bottom: 5%;
    left: 0;
    margin: 0 2rem;
  }
  
  .blog-title {
    font-size: 33px;
    margin-bottom: 1rem;
  }
  
  .blog-para {
    font-size: 16px;
    margin-bottom: 0;
  }
  
  .blog-button {
    line-height: 24px;
    font-size: 15px;
    margin-top: 0.5rem;
  }
  
  .card-03 {
   min-width: 100%;
    height: 540px;
    margin: 20px;
    margin-left: 5px;
  }
  
  .author-info {
    font-size: 18px;
  }
  
  .blog-author {
    font-size: 13px;
  }
  
  .blog-two {
    font-size: 28px;
  }
  
  .blog-para-two {
    font-size: 14px;
  }
  
  .read-post-blog {
    line-height: 26px;
  }  
}


@media only screen and (max-width: 450px) {
  .title-bloggie {
    font-size: 38px;
  }
  
  .blog-content-one {
    bottom: 5%;
    left: 0;
    margin: 0 2rem;
  }
  
  .blog-title {
    font-size: 33px;
    margin-bottom: 1rem;
  }
  
  .blog-para {
    font-size: 16px;
    margin-bottom: 0;
  }
  
  .blog-button {
    line-height: 24px;
    font-size: 15px;
    margin-top: 0.5rem;
  }
  
  .card-03 {
    min-width: 310px;
    height: 520px;
    margin: 20px;
  }
  

  .blog-author {
    font-size: 13px;
  }
  
  .blog-two {
    font-size: 28px;
  }
  
  .blog-para-two {
    font-size: 14px;
  }
  
  .read-post-blog {
    line-height: 26px;
  }
  
}
