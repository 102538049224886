.login-auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border: 1px solid var(--color-gray);
  background-color: #040404;
  max-width: 500px;
  margin: 6rem auto 0;
}


.error-login {
  color: red;
  margin-bottom: 1rem;
}


.login-auth {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}


.auth-h2 {
  margin-bottom: 2rem;
  text-align: center;
  font-family: var(--font-base);
  color: var(--color-white);
  font-size: 30px;
  text-transform: capitalize;
}


.auth-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
 
}


.login-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--color-white);
  font-family: var(--font-alt);
  font-size: 23px;
  font-weight: 400;
}



.password-input-container {
  position: relative;
}

.password-input {
  padding-right: 2.5rem;
}

.password-toggle-btn {
  position: absolute;
  top: 80%;
  right: 0.75rem;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 25px;
}

.password-toggle-btn svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #ccc;
}

.password-toggle-btn:hover svg {
  fill: #999;
}

.password-toggle-btn:focus {
  outline: none;
}

.input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
}

.login-submit-btn[type="submit"] {
  background-color: var(--color-gray);
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  font-family: var(--font-alt);
}

.login-submit-btn:hover {
  background-color: var(--color-grey);
}

.forgot-password-container {
  margin-top: 1rem;
  text-align: center;
}

.link-button {
  background-color: transparent;
  border: none;
  color: #0066ff;
  text-decoration: underline;
  cursor: pointer;
}

.or-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  width: 90%;
}

.or-divider span {
  flex: 1;
  border-bottom: 1px solid var(--color-golden);
}

.google-login-container {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-auth {
  margin-top: 1rem;
  text-align: center;
}

.signup-auth-para {
  font-size: 1rem;
  font-family: var(--font-alt);
  color: var(--color-white);
}

.signup-auth-para:hover {
  color: var(--color-grey);
}


@media only screen and (max-width: 569px) {
  .login-auth-container {
    max-width: 90%;
    padding: 1rem;
  }
 
  .auth-h2 {
    font-size: 24px;
  }
 
  .auth-group {
    margin-bottom: 1rem;
  }
 
  .login-label {
    font-size: 18px;
  }
 
  .input {
    font-size: 0.9rem;
  }
 
  .login-submit-btn {
    font-size: 0.9rem;
  }
 
  .forgot-password-container {
    margin-top: 0.5rem;
  }
 
  .signup-auth {
    margin-top: 1.5rem;
    font-size: 0.9rem;
    }
   
    .signup-auth-para {
    font-size: 0.9rem;
    }
  }
