*{
  margin: 0;
  padding: 0;
}

.chief-text-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 8rem;  
}

.chief-text-image h3 {
  font-size: 30px;
  color: var(--color-white);
  text-transform: capitalize;
  font-family: var(--font-base);
  font-weight: 600;
}

.chief-text-image h1 {

  font-size: 80px;
  margin-bottom:6rem;
  font-family: var(--font-base);
  font-weight: 500;
  text-transform: capitalize;
  color: var(--color-golden);
} 


.swiper {
  width: 100%;
  background-color: #000;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chief-text-image img {
  width:50px
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  margin-top: -20rem;
} 

.swiper-slide img {
  display: block;
  width: 100%;
  -webkit-box-reflect: below -140px linear-gradient(
    transparent,transparent, 
    rgba(0, 0, 0, 0.212)
  )
}

@media screen and (max-width: 1440px) {
  .swiper-slide img {
    -webkit-box-reflect: below -15px linear-gradient(
    transparent,transparent, 
    rgba(0, 0, 0, 0.212)
      )
    }
  
    .chief-text-image h3 {
      margin-top: -2rem;
    }
  
    .swiper{
      min-height: 100vh;
    } 

    .chief-text-image h1 {
      font-size: 80px;
      margin-bottom: 4rem;
      margin-top: 1rem;
    }
  }

@media screen and (max-width: 1024px) {
.swiper-slide img {
  -webkit-box-reflect: below -15px linear-gradient(
  transparent,transparent, 
  rgba(0, 0, 0, 0.212)
    )
  }

  .chief-text-image h3 {
    margin-top: -2rem;
  }

  .swiper{
    min-height: 20vh;
    margin-top: 1rem;
    padding-top: 20rem;
  } 
  
  .chief-text-image h1 {
    font-size: 75px;
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .swiper-slide img {
    -webkit-box-reflect: below -15px linear-gradient(
      transparent,transparent, 
      rgba(0, 0, 0, 0.212)
      )
    }

  .swiper{
    min-height: 20vh;
    padding-top: 20rem;
  } 

  .chief-text-image h1 {
    font-size: 50px;
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .swiper-slide img {
    -webkit-box-reflect: below -15px linear-gradient(
    transparent,transparent, 
    rgba(0, 0, 0, 0.212)
      )
    }
  
    .chief-text-image h3 {
      margin-top: -2rem;
      font-size: 27px;
    }
  
    .swiper{
      margin-top: -2rem;
    } 

    .chief-text-image h1 {
      font-size: 32px;
      text-align: center;
      margin-bottom: 4rem;
      margin-top: 1rem;
    }
  }
