body.modal-open,
html.modal-open {
  overflow: hidden;
  height: 100%;
}

.vip__container.modal-open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.959);
  display: flex;
  justify-content: center;
  align-items: center;
}

.vip__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.vip__content {
  background-color: #000;
  border: 2px solid var(--color-golden);
  padding: 2rem;
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
}

.vip__content h2 {
  color: var(--color-white);
  font-size: 24px;
  margin-bottom: 1rem;
}

.vip__content input {
  width: 100%;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  margin-bottom: 1rem;
  outline: none;
  border-radius: 3px;
}

.vip__content .eye-icon {
  position: absolute;
  top: 42%;
  right: 40px;
  font-size: 25px;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--color-black);
}

.vip__content button {
  padding: 0.5rem 1rem;
  margin: 1rem;
  background-color: var(--color-grey);
  border: none;
  cursor: pointer;
  border-radius: 3px;
}

.vip__content button:hover {
  background-color: var(--color-white);
}
