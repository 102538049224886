.nested-comments {
  margin-top: 20px;
  padding: 40px;
  background-color: #0a0a0a;
  border-radius: 10px;
}

.nested-comment-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.nested-comment-input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  outline: none;
}

.nested-comment-submit {
  padding: 5px 10px;
  background-color: var(--color-gray);
  color: #fff;
  border: none;
  margin-right: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.nested-close-button {
  padding: 5px 10px;
  background-color: var(--color-grey);
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nested-comment-submit:hover,
.nested-close-button:hover {
  background-color: #1b1a1a;
}


.nested-comments-list {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid var(--color-golden);
  background-color: #141414;
  padding: 20px;
}


.nested-comment-vip {
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid #0c0c0c;
  border-radius: 10px;
}


.nested-vip-comment-avatar {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.nested-vip-avatar-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #FF0000;
  color: #fff;
  font-weight: bold;
}

.nested-vip-avatar-name {
  margin-left: 10px;
  font-weight: bold;
  color: var(--color-white);
}

.nested-vip-comment-date {
  margin-top: 5px;
  margin-left: 10px;
  font-size: 12px;
  color: #d8d4d4;
}

.nested-vip-comment-content {
  margin-bottom: 5px;
  color: #d8d4d4;
}

.nested-vip-comment-actions {
  display: flex;
  align-items: center;
}

.nested-delete-button,
.nested-edit-button {
  padding: 5px 10px;
  margin-right: 5px;
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nested-edit-button {
  background-color: var(--color-gray);
}

.nested-delete-button {
  background-color: var(--color-grey);
}

.nested-delete-button:hover,
.nested-edit-button:hover {
  background-color: #000;
}

.nested-delete-button,
.nested-edit-button,
.nested-update-button,
.nested-cancel-button {
  padding: 5px 10px;
  margin-right: 5px;
  margin-top: 5px;
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nested-edit-button,
.nested-update-button {
  background-color: var(--color-gray);
}

.nested-delete-button,
.nested-cancel-button {
  background-color: var(--color-grey);
}

.nested-delete-button:hover,
.nested-edit-button:hover,
.nested-update-button:hover,
.nested-cancel-button:hover {
  background-color: #000;
}

.nested-unlike-button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: transparent;
  color: green;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.nested-like-button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: transparent;
  color: green;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.nested-heart-icon {
  margin-right: 5px;
}
