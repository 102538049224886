.hero-image {
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  background: url(../../assets/header5.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hero-image::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.hero-content {
  display: flex; 
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  
}

.hero-content-inner h3 {
  font-size: 42px;
  font-family: var(--sans-serif);
  font-weight: 500;
  color: var(--color-white);
  text-transform: capitalize;
}

.hero-content-inner h1 {
  font-size: 105px;
  font-family: var(--Open Sans);
  font-weight: 500;
  text-transform: capitalize;
  color: var(--color-golden);
  margin-bottom: 2rem;
}

.hero-content-inner p {
  width: 100%;
  font-size: 25px;
  margin: 0 auto;
  font-family: var(--font-alt);
  font-weight: normal;
  text-transform: capitalize;
  color: var(--color-white);
  line-height: 30px;
  padding: 0 40px!important;
}

.content__buttonL {
  border-color: var(--color-golden);
  background: transparent;
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 22px;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  margin-top: 40px;
  outline: none;
  cursor: pointer;
  width: 170px;
  height: 47px;
  transition: all ease .2s;
}

.content__buttonL:hover {
  border-color: var(--color-crimson);
  color: var(--color-golden);
  background-color:#000;
}

@media (max-width: 1494px) {
  .hero-content-inner h3 {
    font-size: 38px;
  }
  
  .hero-content-inner h1 {
    font-size: 85px;
  }
}

@media (max-width: 1200px) {
  .hero-content-inner h3 {
    font-size: 34px;
  }
  
  .hero-content-inner h1 {
    font-size: 80px;
  }
  
  .hero-content-inner p {
    font-size: 21px;
  }
}

@media (max-width: 1024px) {
  .hero-content-inner h3 {
    font-size: 34px;
  }
  
  .hero-content-inner h1 {
    font-size: 75px;
  }
  
  .hero-content-inner p {
    font-size: 19px;
    padding: 10px
  }
}

@media (max-width: 880px) {
  .hero-content-inner h3 {
    font-size: 30px;
  }
  
  .hero-content-inner h1 {
    font-size: 70px;
  }
}

@media (max-width: 782px) {
  .hero-content-inner h3 {
    font-size: 28px;
    margin-top: 4rem;
    margin-bottom: none;
  }
  
  .hero-content-inner h1 {
    font-size: 55px;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  .hero-content-inner p {
    font-size: 16px;
    line-height: 24px;
    padding: 10px
  }
  
  .content__buttonL {
    font-size: 18px;
    padding: 0.5rem 1rem;
    width: 140px;
    height: 40px;
  }
}

@media (max-width: 530px) {
  .hero-content-inner h3 {
    font-size: 23px;
    margin-top: 4rem;
    margin-bottom: none;
  }
  
  .hero-content-inner h1 {
    font-size: 50px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .hero-content-inner h3 {
    font-size: 18px;
  }
  .hero-content-inner h1 {
    font-size: 34px;
  }
  
  .hero-content-inner p {
    font-size: 13px;
    line-height: 22px;
  }
  
  .content__buttonL {
    margin-bottom: 1rem;
    padding: 0;
  }
}
