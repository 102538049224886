.welcome-logout-container {
  display: flex;
  flex-direction:row;
  align-items: right;
  justify-content: right;
  height: 100%;
  margin: 10px;
  margin-top: -3.2rem;
}


.welcome-message {
  margin-right: 5px;
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-alt);
  font-weight: 400;
}


.vertical-line {
  border-left: 1px solid var(--color-gray);
  height: 30px;
  margin: 0 5px;
  margin-top: -5px;
}


.login-button {
  margin-top: -5px;
}


.logout-button-btn {
  margin-right: 15px;
  margin-left: 10px;
  font-family: var(--font-alt);
  padding: 0.3rem .8rem;
  border: none;
  background-color: var(--color-gray);
  color: var(--color-white);
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}


.logout-button-btn:hover {
  background-color: var(--color-black);
  color: var(--color-golden);
}




@media screen and (max-width: 1738px) {
  .welcome-logout-container {
    margin-top: -1rem;
  }


  .welcome-message {
    font-size: 12px;
  }


  .logout-button-btn {
    font-size: 12px;
    padding: 0.2rem 0.5rem;
    margin-top: 2px;
  }
}


@media screen and (max-width: 1474px) {
  .welcome-logout-container {
    margin-top: -1rem;
  }


  .welcome-message {
    font-size: 12px;
  }


  .logout-button-btn {
    font-size: 12px;
    padding: 0.2rem 0.5rem;
    margin-top: 2px;
  }
}

@media screen and (max-width: 650px) {
  .logout-button-btn {
    font-size: 12px;
    padding: 0.2rem 0.5rem;
    margin-right: 30px;
  }
}
