.faith-container-full {
  margin: 0;  
  padding: 0;
}

.faith-container-main {
  margin-top: 5rem;
}

.faith-container-row {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.faith-card-two {
  position: relative;
  flex: 1;
  max-width: 800px;
  height: auto;
  margin: 20px;
}

.faith-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid var(--color-golden);
  -webkit-mask-image: linear-gradient(black, transparent);
  mask-image:linear-gradient(black, transparent);
}

.faith-title {
  margin-top: 1rem;
  font-family: var(--font-base);
  color: var(--color-white);
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
}

.faith-subtitle {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 55px;
  font-weight: 500;
  text-transform: capitalize;
}

.faith-card-one {
  position: relative;
  flex: 1;
  max-width: 300px;
  margin: 20px;
}

.faith-img-card {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid var(--color-golden);
}

.p__opensansA {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 26px;
  font-size: 15px;
}



@media (max-width: 1436px) {
  .faith-title {
    font-size: 23px;
    margin-top: 0;
  }

  .faith-subtitle {
    font-size: 55px;
    margin-top: 0;
  }

  .p__opensansA {
    font-size: 14px;
  }
}

@media (max-width: 1375px) {
  .faith-card-two {
    height: 850px;
    margin: 20px;
}

  .faith-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 1072px) {
  .faith-subtitle {
    font-size: 50px;
  }
}

@media (max-width: 990px) {
  .faith-card-two {
    flex: 100%;
    max-width: 760px;
    height: auto;
  }

  .faith-img {
    height: 550px;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .faith-card-two {
    flex: 100%;
    max-width: 760px;
  }

  .faith-img {
    height: 450px;
    object-fit: cover;
    -webkit-mask-image: linear-gradient(black, transparent);
    mask-image:linear-gradient(black, transparent);
  }
}

@media (max-width: 450px) {
  .faith-card-two {
    flex: 100%;
    max-width: 760px;
  }

  .faith-img {
    height: 340px;
    object-fit: cover;
  }

  .faith-card-one {
    margin: 10px;
  }

  .faith-title {
    font-size: 20px;
  }

  .faith-subtitle {
    font-size: 30px;
  }

  .p__opensansA {
    font-size: 13px;
  }
}