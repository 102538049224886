.app__footerOverlay {
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.app__footerOverlay-black {
  background:  url(../../assets/nature3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 45%;
}

.app__footerOverlay-img {
  height: 75%;
} 

@media screen and (max-width: 769px){
.app__footerOverlay-img {
  height: 45%;
}
}