.science-main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(180deg,
  #000 50%,
  #01e1ff10 100%); 
}

.science-container {
  position: relative;
  width: 1000px;
  margin: auto;
  height: 600px;
  padding: 4rem 5rem;
  background-image: linear-gradient(180deg,
  #01e1ff10 0%,
  #000 100%); 
}

.textblock {
  width: 45%;
}

.science-feather-img {
  width: 45px;
  
}

.science-subtitle {
  color: var(--color-white);
  font-family: var(--sans-serif);
  font-size: 21px;
  text-transform: capitalize;
  letter-spacing: 0.04em;
}


.science-title {
  font-size: 50px;
  color: var(--color-golden);
  font-family: var(--font-base);
  text-transform: capitalize;
  font-weight: 900;
}

.science-para {
  margin-bottom: 1.3rem;
  color: var(--color-white);
  font-family: var(--font-alt);
  text-transform: capitalize;
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
}

.science-button {
  display: inline-block;
  margin-top: 1.5rem;
  padding: 0.5rem 1.5rem;
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  /* line-height: 28px; */
  font-size: 16px;
  border-radius: 5px;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  width: 110px;
  height: 38px;
  transition: all ease .2s;
}

.science-button:hover {
  color: var(--color-white);
  border: 1px solid var(--color-golden);
  background: transparent;
}

.astrology-img {
  position: absolute;
  width: 65%;
  height: 48vh;
  top: 13%;
  right: -18%;
  border: 2px solid var(--color-golden);
  box-shadow: 5px 4px 10px rgba(37, 37, 37, 0.5);
}


@media screen and (max-width: 1600px) {
  .science-container {
    width: 950px;
  }

  .astrology-img {
    width: 60%;
    top: 16%;
    right: -12%;
  }
}

@media screen and (max-width: 1250px) {
  .science-container {
    width: 800px;
    height: 600px;
  }
  
  .textblock {
    width: 55%;
  }
  
  .science-subtitle {
    font-size: 21px;
  }
  
  .science-title {
    font-size: 48px;
  }
  
  .science-para {
    font-size: 15px;
  }
  
  .astrology-img {
    width: 55%;
    top: 18%;
    right: -16%;
  }
}

@media screen and (max-width: 1024px) {
  .science-container {
    width: 680px;
    height: 600px;
  }
  
  .textblock {
    width: 55%;
  }
  
  .science-subtitle {
    font-size: 20px;
  }
  
  .science-title {
    font-size: 42px;
  }
  
  .science-para {
    font-size: 15px;
  }
  
  .astrology-img {
    width: 60%;
    height: 48vh;
    right: -16%;
    top: 18%;
  }
}

@media screen and (max-width: 920px) {
  .science-container {
    width: 600px;
    height: 650px;
    margin-right: 9rem;
  }
  
  .textblock {
    width: 55%;
    margin-top: -2rem;
  }
  
  .science-subtitle {
    font-size: 18px;
  }
  
  .science-title {
    font-size: 36px;
  }
  
  .science-para {
    font-size: 14px;
  }
  
  .astrology-img {
    width: 55%;
    height: 44vh;
    right: -14%;
    top: 18%;
  }
}

@media screen and (max-width: 768px) {
  .science-main-container {
    padding: 0rem;
  }

  .science-container {
    width: 100%;
    line-height: 24px; 
    height: 100vh;
  }

  .textblock {
    width: 100%;
  }

  .science-subtitle {
    font-size: 20px;
    margin-left: 1rem;
  }

  .science-feather-img {
   margin-left: 1rem;
  }
  
  .science-title {
    margin-top: 1rem;
    margin-left: 1rem;
    font-size: 45px;
  }
  
  .science-para {
    margin-top: 1rem;
    margin-left: 1rem;
    font-size: 18px;
    line-height: 26px;
  }
  
  .astrology-img {
    display: none;
  }

  .science-button {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 508px) {
  .science-main-container {
    height: 900px;
  }


  .science-container {
    width: 100%;
    height: 600px;
    line-height: 20px; 
    
  }

  .textblock {
    width: 100%;
    margin-left: -1.9rem;
  }

  .science-feather-img {
    margin-left: 0.7rem!important;
   }
  
  .science-subtitle {
    font-size: 14px;
  }

  .science-feather-img {
    margin-left: -1rem;
    
  }
  
  .science-title {
    margin-top: 1rem;
    font-size: 28px;
  }
  
  .science-para {
    margin-top: 1rem;
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .science-main-container {
    height: 750px;
  }

  .science-container {
    width: 100%;
    height: 550px;
    padding: 3rem 2rem;
    line-height: 20px;
  }
}

  @media screen and (max-width: 360px) {
  .science-main-container {
    padding: 1rem;
    width: 360px;
    height: 800px;
  }

  .science-container {
    width: 100%;
    height: 550px;
    padding: 2rem 1rem;
    line-height: 20px;
  }

  .textblock {
    width: 100%;
  }

  .science-subtitle {
    font-size: 14px;
    margin-top: 2rem;
  }
  
  .science-title {
    font-size: 28px;
  }
  
  .science-para {
    margin-top: 1rem;
    font-size: 14px;
  }

  .astrology-img {
    display: none;
  }
}