.sidebar-container {
  position: fixed;
  top: 17%;
  left: 0; 
  width: 300px;
  height: 90vh; 
  padding: 10px;
  background-color: #000; 
  z-index: 1;
}

.sidebar-list {
  list-style-type: none;
  font-size: 13px;
  font-family: var(--font-alt);
  margin-left: 15px; 
}

.sidebar-item {
  margin-top: 24px; 
}

.sidebar-link {
  color: var(--color-white);
  text-decoration: none;
  background-color: transparent; 
  transition: 0.3s ease-in-out;
}

.sidebar-link:hover {
  background-color: var(--color-golden);
  padding: 5px;
  border-radius: 40px;
}

.sidebar-icon {
  color: var(--color-golden);
  margin-right: 0.9rem;
}

.sidebar-link:hover .sidebar-icon {
  color: var(--color-white);
}

.sidebar-footer {
  position: absolute;
  bottom: 10%; 
  font-size: 14px;  
  color: var(--color-white);
  font-family: var(--font-alt);
  margin-left: 5px;
}

@media only screen and (max-width: 900px) {
  .sidebar-container {
    position: fixed;
    top: 8%;
    left: 0;
    width: 100%;
    height: 10vh;
    padding: 10px;
    z-index: 1;
    background-color: transparent;
  }

  .sidebar-list {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    font-size: 14px;
    font-family: var(--font-alt);
    margin-left: 20px;
    margin-top: 1rem;
  }

  .sidebar-item {
    margin-top: 1rem;
    margin-right: 20px;
    white-space: nowrap;
    height: 30px;
  }

  .sidebar-footer {
    display: none;
  }
}





