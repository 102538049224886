.efuru-container-full {
  margin: 0;  
  padding: 0;
  background-image: linear-gradient(140deg,
  #000 45%, 
  #040d3a41 70%, 
  #aa6a4057 80%,
  #0a4e4be1 100%);
  box-shadow: 0 10px 20px 
  rgba(0,0,0,0.19), 0 6px 6px 
  rgba(0,0,0,0.23);
}

.efuru-container-main {
  margin-top: 5rem;
}

.efuru-container-row {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.efuru-card-two {
  position: relative;
  flex: 1;
  max-width: 800px;
  height: auto;
  margin: 20px;
}

.efuru-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid var(--color-golden);
  -webkit-mask-image: linear-gradient(black, transparent);
  mask-image:linear-gradient(black, transparent);
}

.efuru-title {
  margin-top: 1rem;
  font-family: var(--font-base);
  color: var(--color-white);
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
}

.efuru-subtitle {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 55px;
  font-weight: 500;
  text-transform: capitalize;
}

.efuru-card-one {
  position: relative;
  flex: 1;
  max-width: 300px;
  margin: 20px;
}

.efuru-img-card {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid var(--color-golden);
}

.p__opensansA {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 26px;
  font-size: 15px;
}



@media (max-width: 1436px) {
  .efuru-title {
    font-size: 23px;
    margin-top: 0;
  }

  .efuru-subtitle {
    font-size: 55px;
    margin-top: 0;
  }

  .p__opensansA {
    font-size: 14px;
  }
}

@media (max-width: 1375px) {
  .efuru-card-two {
    height: 850px;
    margin: 20px;
}

.efuru-card-two {
  height: 1050px;
}

  .efuru-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 1072px) {
  .efuru-card-two {
    height: 1100px;
  }

  .efuru-subtitle {
    font-size: 50px;
  }
}

@media (max-width: 990px) {
  .efuru-card-two {
    flex: 100%;
    max-width: 760px;
    height: auto;
  }

  .efuru-img {
    height: 550px;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .efuru-card-two {
    flex: 100%;
    max-width: 760px;
  }

  .efuru-img {
    height: 450px;
    object-fit: cover;
  }
}

@media (max-width: 450px) {
  .efuru-card-two {
    flex: 100%;
    max-width: 760px;
  }

  .efuru-img {
    height: 340px;
    object-fit: cover;
  }

  .efuru-card-one {
    margin: 10px;
  }

  .efuru-title {
    font-size: 20px;
  }

  .efuru-subtitle {
    font-size: 30px;
  }

  .p__opensansA {
    font-size: 13px;
  }
}