.comments-vip {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #140c07c4, #022029, #010005);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.comments-vip.open {
  opacity: 1;
  visibility: visible;
}

.comments-vip-container {
  max-width: 800px;
  width: 100%;
  background-color: #000;
  padding: 20px;
  border-radius: 4px;
  margin: 10px;
}

.comments-vip-header {
  text-align: center;
  margin-bottom: 20px;
}

.header-title {
  font-size: 28px;
  font-weight: bold;
  color: var(--color-white);
}

.comment-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.comment-input {
  flex-grow: 1;
  height: 35px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.comment-buttons-container {
  margin-left: 10px;
}

.comment-submit,
.close-button {
  height: 35px;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 5px;
}

.comment-submit {
  background-color: var(--color-gray);
}

.close-button {
  background-color: var(--color-grey);
}

.comment-submit:hover,
.close-button:hover {
  background-color: #1b1a1a;
}

.comments-vip-body {
  margin-bottom: 20px;
}

.comments-list {
  margin-top: 10px;
  max-height: 500px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.comments-list::-webkit-scrollbar {
  display: none;
}

.comment-vip {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border: 2px solid #333131;
  background-color: #141414;
  border-radius: 4px;
}

.vip-comment-avatar {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.vip-avatar-circle {
  width: 30px;
  height: 30px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 5px;
}

.vip-avatar-name {
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
  margin-left: 5px;
  color: var(--color-white);
}

.vip-comment-date {
  font-size: 12px;
  margin-top: 10px;
  margin-left: 10px;
  color: #d8d4d4;
}

.vip-comment-content {
  margin-bottom: 5px;
  padding: 10px;
  color: #d8d4d4;
  overflow-wrap: break-word;
  word-wrap: break-word;
}


.vip-comment-actions {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.edit-button,
.delete-button,
.update-button,
.cancel-button {
  height: 30px;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 5px;
}

.update-input {
  height: 30px;
  width: 400px;
  padding: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  outline: none;
}

.edit-button,
.update-button {
  background-color: var(--color-gray);
}

.delete-button,
.cancel-button {
  background-color: var(--color-grey);
}

.edit-button:hover,
.delete-button:hover,
.update-button:hover,
.cancel-button:hover {
  background-color: #000000;
}

.like-button,
.unlike-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 22px;
  color: white;
}

.heart-icon {
  margin-right: 5px;
  color: green;
}

.nested-reply-button {
  font-size: 16px;
  margin-left: 5px;
  background-color: transparent;
  color: var(--color-white);
  outline: none;
  border: none;
 transition: ease-in-out 0.3s;
}

.nested-reply-button:hover {
  color: var(--color-grey);
}

@media screen and (max-width: 600px) {
  .comments-vip-container {
    padding: 10px;
  }

  .header-title {
    font-size: 24px;
  }

  .comment-input {
    height: 30px;
  }

  .comment-submit,
  .close-button,
  .edit-button,
  .delete-button,
  .update-button,
  .cancel-button {
    height: 30px;
    padding: 5px 8px;
    font-size: 12px;
    margin-right: 3px;
  }

  .vip-avatar-circle {
    width: 20px;
    height: 20px;
    font-size: 12px;
    margin-left: 3px;
    margin-top: 3px;
  }

  .vip-avatar-name {
    font-size: 14px;
    margin-top: 3px;
    margin-left: 3px;
  }

  .vip-comment-content {
    margin-left: 5px;
    font-size: 12px;
  }

  .edit-button,
  .update-button {
    font-size: 12px;
  }

  .delete-button,
  .cancel-button {
    font-size: 12px;
  }

  .like-button,
  .unlike-button {
    font-size: 18px;
  }

  .heart-icon {
    font-size: 12px;
    margin-right: 3px;
  }
}

.nested-reply {
  color: #fff;
} 
