.chief-hero-image {
  background: url(../../assets/pageimg1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
}

.chief-content {
  display: flex; 
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-image:linear-gradient(to right, 
  rgba(0, 0, 0, 1),
  rgba(0, 0, 0, .3),
  rgba(0, 0, 0, .1),
  rgba(0, 0, 0, 0));
} 

.chief-content h3 {
    font-size: 42px;
    font-family: var(--sans-serif);
    font-weight: 500;
    color: var(--color-white);
    text-transform: capitalize;
} 

.chief-content h1 {
  font-size: 105px;
  font-family: var(--Open Sans);
  font-weight: 500;
  text-transform: capitalize;
  color: var(--color-golden);
} 

.chief-content p {
  display: flex; 
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 25px;
  margin: 0 auto;
  font-family: var(--font-alt);
  font-weight: normal;
  text-transform: capitalize;
  color: var(--color-white);
  line-height: 30px;
  padding: 0 40px!important;
}  

.chief-content span {
  font-size: 23px;
  color: var(--color-white);
  font-family: var(--font-alt);
  font-weight: 400;
}


@media (max-width: 1494px) {
  .chief-content h3 {
    font-size: 38px;
  }
  
  .chief-content h1 {
    font-size: 85px;
  }
}

@media (max-width: 1200px) {
  .chief-content h3 {
    font-size: 34px;
  }
  
  .chief-content h1 {
    font-size: 80px;
  }
  
  .chief-content p {
    font-size: 21px;
  }
}

 @media (max-width: 1024px) {
  .chief-content h3 {
    font-size: 34px;
  }
  
  .chief-content h1 {
    font-size: 75px;
  }
  
  .chief-content p {
    font-size: 19px;
    padding: 10px
  }
}

@media (max-width: 880px) {
  .chief-content h3 {
    font-size: 30px;
  }
  
  .chief-content h1 {
    font-size: 70px;
  }
}

@media (max-width: 782px) {
  .chief-hero-image {
    height: 75vh;
  }
  
  .chief-content {
    height: 75vh;
  } 

  .chief-content h3 {
    font-size: 28px;
  }
  
  .chief-content h1 {
    font-size: 55px;
  }
  
  .chief-content p {
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
  }

  .chief-content  span {
    font-size: 12px;
  }
}

@media (max-width: 530px) {
.chief-content h3 {
    font-size: 23px;
  }

  .chief-content h1 {
    font-size: 50px;
  }
}

@media (max-width: 480px) {
  .chief-hero-image {
    height: 60vh;
  }
  
  .chief-content {
    height: 60vh;
  } 

  .chief-content h3 {
    font-size: 18px;
  }

  .chief-content h1 {
    font-size: 34px;
  }
  
  .chief-content p {
    font-size: 13px;
    line-height: 22px;
  }
}









